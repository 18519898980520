<template>
    <div class="history-slider">
        <div id="leftBox" class="left box">
            <svg @click="prevSwipe" width="123"
                 height="11"
                 class="icon"
                 viewBox="0 0 123 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                <line x1="1.33594" y1="5.49902" x2="121.336" y2="5.49903" stroke="#F40146" stroke-linecap="round"/>
                <mask id="path-2-inside-1_729_10178" fill="white">
                    <path fill-rule="evenodd" clip-rule="evenodd"
                          d="M118.061 0.21778L122.626 4.9709C122.906 5.26257 122.906 5.73547 122.626 6.02715L118.061 10.7803C117.78 11.0719 117.326 11.0719 117.046 10.7803C116.766 10.4886 116.766 10.0157 117.046 9.72402L121.104 5.49902L117.046 1.27403C116.766 0.982353 116.766 0.509455 117.046 0.21778C117.326 -0.0738953 117.78 -0.0738953 118.061 0.21778Z"/>
                </mask>
                <path fill-rule="evenodd" clip-rule="evenodd"
                      d="M118.061 0.21778L122.626 4.9709C122.906 5.26257 122.906 5.73547 122.626 6.02715L118.061 10.7803C117.78 11.0719 117.326 11.0719 117.046 10.7803C116.766 10.4886 116.766 10.0157 117.046 9.72402L121.104 5.49902L117.046 1.27403C116.766 0.982353 116.766 0.509455 117.046 0.21778C117.326 -0.0738953 117.78 -0.0738953 118.061 0.21778Z"
                      fill="#940128"/>
                <path
                    d="M122.626 4.9709L124.068 3.58548L124.068 3.58548L122.626 4.9709ZM122.626 6.02715L124.068 7.41257H124.068L122.626 6.02715ZM118.061 10.7803L119.503 12.1657L118.061 10.7803ZM117.046 9.72402L115.604 8.33859H115.604L117.046 9.72402ZM121.104 5.49902L122.546 6.88445L123.877 5.49902L122.546 4.1136L121.104 5.49902ZM124.068 3.58548L119.503 -1.16764L116.618 1.6032L121.183 6.35632L124.068 3.58548ZM124.068 7.41257C125.092 6.34688 125.092 4.65117 124.068 3.58548L121.183 6.35632C120.72 5.87398 120.72 5.12407 121.183 4.64172L124.068 7.41257ZM119.503 12.1657L124.068 7.41257L121.183 4.64172L116.618 9.39484L119.503 12.1657ZM115.604 12.1657C116.671 13.2768 118.436 13.2768 119.503 12.1657L116.618 9.39484C117.125 8.86708 117.982 8.86708 118.488 9.39484L115.604 12.1657ZM115.604 8.33859C114.58 9.40429 114.58 11.1 115.604 12.1657L118.488 9.39484C118.952 9.87719 118.952 10.6271 118.488 11.1094L115.604 8.33859ZM119.662 4.1136L115.604 8.33859L118.488 11.1094L122.546 6.88445L119.662 4.1136ZM115.604 2.65945L119.662 6.88445L122.546 4.1136L118.488 -0.111395L115.604 2.65945ZM115.604 -1.16764C114.58 -0.101951 114.58 1.59376 115.604 2.65945L118.488 -0.111395C118.952 0.370948 118.952 1.12086 118.488 1.6032L115.604 -1.16764ZM119.503 -1.16764C118.436 -2.27875 116.671 -2.27875 115.604 -1.16764L118.488 1.6032C117.982 2.13096 117.125 2.13096 116.618 1.6032L119.503 -1.16764Z"
                    fill="#F40146" mask="url(#path-2-inside-1_729_10178)"/>
            </svg>
            <div class="mobile-arrows">
                <div @click="prevSwipe" class="item">
                    <svg width="63" height="12"
                         viewBox="0 0 63 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <line x1="0.5" y1="-0.5" x2="60.7211" y2="-0.5"
                              transform="matrix(-1 -8.74228e-08 -8.74228e-08 1 62.5 6.9541)" stroke="#F40146"
                              stroke-linecap="round"/>
                        <mask id="path-2-inside-1_2675_23422" fill="white">
                            <path fill-rule="evenodd" clip-rule="evenodd"
                                  d="M5.05468 1.17286L0.489408 5.92598C0.20926 6.21765 0.20926 6.69055 0.489408 6.98223L5.05468 11.7353C5.33483 12.027 5.78904 12.027 6.06919 11.7353C6.34933 11.4437 6.34933 10.9708 6.06919 10.6791L2.01117 6.4541L6.06919 2.22911C6.34933 1.93743 6.34933 1.46453 6.06919 1.17286C5.78904 0.881183 5.33483 0.881183 5.05468 1.17286Z"/>
                        </mask>
                        <path fill-rule="evenodd" clip-rule="evenodd"
                              d="M5.05468 1.17286L0.489408 5.92598C0.20926 6.21765 0.20926 6.69055 0.489408 6.98223L5.05468 11.7353C5.33483 12.027 5.78904 12.027 6.06919 11.7353C6.34933 11.4437 6.34933 10.9708 6.06919 10.6791L2.01117 6.4541L6.06919 2.22911C6.34933 1.93743 6.34933 1.46453 6.06919 1.17286C5.78904 0.881183 5.33483 0.881183 5.05468 1.17286Z"
                              fill="#940128"/>
                        <path
                            d="M0.489408 5.92598L-0.953022 4.54055L-0.953021 4.54055L0.489408 5.92598ZM0.489408 6.98223L-0.953021 8.36765H-0.953022L0.489408 6.98223ZM5.05468 11.7353L3.61225 13.1208L5.05468 11.7353ZM6.06919 10.6791L7.51162 9.29367H7.51162L6.06919 10.6791ZM2.01117 6.4541L0.568737 7.83953L-0.761934 6.4541L0.568737 5.06868L2.01117 6.4541ZM-0.953021 4.54055L3.61225 -0.212566L6.49711 2.55828L1.93184 7.3114L-0.953021 4.54055ZM-0.953022 8.36765C-1.9766 7.30196 -1.9766 5.60625 -0.953022 4.54055L1.93184 7.3114C2.39512 6.82906 2.39512 6.07914 1.93184 5.5968L-0.953022 8.36765ZM3.61225 13.1208L-0.953021 8.36765L1.93184 5.5968L6.49711 10.3499L3.61225 13.1208ZM7.51162 13.1208C6.44442 14.2319 4.67945 14.2319 3.61225 13.1208L6.49711 10.3499C5.99021 9.82216 5.13366 9.82216 4.62676 10.3499L7.51162 13.1208ZM7.51162 9.29367C8.53519 10.3594 8.53519 12.0551 7.51162 13.1208L4.62676 10.3499C4.16348 10.8323 4.16348 11.5822 4.62676 12.0645L7.51162 9.29367ZM3.45359 5.06868L7.51162 9.29367L4.62676 12.0645L0.568737 7.83953L3.45359 5.06868ZM7.51162 3.61453L3.45359 7.83953L0.568737 5.06868L4.62676 0.843683L7.51162 3.61453ZM7.51162 -0.212566C8.53519 0.853127 8.53519 2.54884 7.51162 3.61453L4.62676 0.843683C4.16348 1.32603 4.16348 2.07594 4.62676 2.55828L7.51162 -0.212566ZM3.61225 -0.212566C4.67945 -1.32368 6.44442 -1.32368 7.51162 -0.212566L4.62676 2.55828C5.13366 3.08604 5.99021 3.08604 6.49711 2.55828L3.61225 -0.212566Z"
                            fill="#F40146" mask="url(#path-2-inside-1_2675_23422)"/>
                    </svg>
                </div>
                <div @click="nextSwipe" class="item">
                    <svg width="63" height="12"
                         viewBox="0 0 63 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <line x1="0.5" y1="-0.5" x2="60.7211" y2="-0.5"
                              transform="matrix(-1 -8.74228e-08 -8.74228e-08 1 62.5 6.9541)" stroke="#F40146"
                              stroke-linecap="round"/>
                        <mask id="path-2-inside-1_2675_23422" fill="white">
                            <path fill-rule="evenodd" clip-rule="evenodd"
                                  d="M5.05468 1.17286L0.489408 5.92598C0.20926 6.21765 0.20926 6.69055 0.489408 6.98223L5.05468 11.7353C5.33483 12.027 5.78904 12.027 6.06919 11.7353C6.34933 11.4437 6.34933 10.9708 6.06919 10.6791L2.01117 6.4541L6.06919 2.22911C6.34933 1.93743 6.34933 1.46453 6.06919 1.17286C5.78904 0.881183 5.33483 0.881183 5.05468 1.17286Z"/>
                        </mask>
                        <path fill-rule="evenodd" clip-rule="evenodd"
                              d="M5.05468 1.17286L0.489408 5.92598C0.20926 6.21765 0.20926 6.69055 0.489408 6.98223L5.05468 11.7353C5.33483 12.027 5.78904 12.027 6.06919 11.7353C6.34933 11.4437 6.34933 10.9708 6.06919 10.6791L2.01117 6.4541L6.06919 2.22911C6.34933 1.93743 6.34933 1.46453 6.06919 1.17286C5.78904 0.881183 5.33483 0.881183 5.05468 1.17286Z"
                              fill="#940128"/>
                        <path
                            d="M0.489408 5.92598L-0.953022 4.54055L-0.953021 4.54055L0.489408 5.92598ZM0.489408 6.98223L-0.953021 8.36765H-0.953022L0.489408 6.98223ZM5.05468 11.7353L3.61225 13.1208L5.05468 11.7353ZM6.06919 10.6791L7.51162 9.29367H7.51162L6.06919 10.6791ZM2.01117 6.4541L0.568737 7.83953L-0.761934 6.4541L0.568737 5.06868L2.01117 6.4541ZM-0.953021 4.54055L3.61225 -0.212566L6.49711 2.55828L1.93184 7.3114L-0.953021 4.54055ZM-0.953022 8.36765C-1.9766 7.30196 -1.9766 5.60625 -0.953022 4.54055L1.93184 7.3114C2.39512 6.82906 2.39512 6.07914 1.93184 5.5968L-0.953022 8.36765ZM3.61225 13.1208L-0.953021 8.36765L1.93184 5.5968L6.49711 10.3499L3.61225 13.1208ZM7.51162 13.1208C6.44442 14.2319 4.67945 14.2319 3.61225 13.1208L6.49711 10.3499C5.99021 9.82216 5.13366 9.82216 4.62676 10.3499L7.51162 13.1208ZM7.51162 9.29367C8.53519 10.3594 8.53519 12.0551 7.51162 13.1208L4.62676 10.3499C4.16348 10.8323 4.16348 11.5822 4.62676 12.0645L7.51162 9.29367ZM3.45359 5.06868L7.51162 9.29367L4.62676 12.0645L0.568737 7.83953L3.45359 5.06868ZM7.51162 3.61453L3.45359 7.83953L0.568737 5.06868L4.62676 0.843683L7.51162 3.61453ZM7.51162 -0.212566C8.53519 0.853127 8.53519 2.54884 7.51162 3.61453L4.62676 0.843683C4.16348 1.32603 4.16348 2.07594 4.62676 2.55828L7.51162 -0.212566ZM3.61225 -0.212566C4.67945 -1.32368 6.44442 -1.32368 7.51162 -0.212566L4.62676 2.55828C5.13366 3.08604 5.99021 3.08604 6.49711 2.55828L3.61225 -0.212566Z"
                            fill="#F40146" mask="url(#path-2-inside-1_2675_23422)"/>
                    </svg>
                </div>
            </div>
            <div v-for="(item,index) in slides"
                 :class="{active:index===activeSlideIndex}"
                 class="item-content">
                <span class="header fira" v-html="item.content"></span>
                <span class="title luxury">{{ item.title }}</span>
                <span class="description fira">{{ item.teaser }}</span>
            </div>
        </div>
        <div class="right box">
            <svg @click="nextSwipe" width="123"
                 height="11"
                 class="icon"
                 viewBox="0 0 123 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                <line x1="1.33594" y1="5.49902" x2="121.336" y2="5.49903" stroke="#F40146" stroke-linecap="round"/>
                <mask id="path-2-inside-1_729_10178" fill="white">
                    <path fill-rule="evenodd" clip-rule="evenodd"
                          d="M118.061 0.21778L122.626 4.9709C122.906 5.26257 122.906 5.73547 122.626 6.02715L118.061 10.7803C117.78 11.0719 117.326 11.0719 117.046 10.7803C116.766 10.4886 116.766 10.0157 117.046 9.72402L121.104 5.49902L117.046 1.27403C116.766 0.982353 116.766 0.509455 117.046 0.21778C117.326 -0.0738953 117.78 -0.0738953 118.061 0.21778Z"/>
                </mask>
                <path fill-rule="evenodd" clip-rule="evenodd"
                      d="M118.061 0.21778L122.626 4.9709C122.906 5.26257 122.906 5.73547 122.626 6.02715L118.061 10.7803C117.78 11.0719 117.326 11.0719 117.046 10.7803C116.766 10.4886 116.766 10.0157 117.046 9.72402L121.104 5.49902L117.046 1.27403C116.766 0.982353 116.766 0.509455 117.046 0.21778C117.326 -0.0738953 117.78 -0.0738953 118.061 0.21778Z"
                      fill="#940128"/>
                <path
                    d="M122.626 4.9709L124.068 3.58548L124.068 3.58548L122.626 4.9709ZM122.626 6.02715L124.068 7.41257H124.068L122.626 6.02715ZM118.061 10.7803L119.503 12.1657L118.061 10.7803ZM117.046 9.72402L115.604 8.33859H115.604L117.046 9.72402ZM121.104 5.49902L122.546 6.88445L123.877 5.49902L122.546 4.1136L121.104 5.49902ZM124.068 3.58548L119.503 -1.16764L116.618 1.6032L121.183 6.35632L124.068 3.58548ZM124.068 7.41257C125.092 6.34688 125.092 4.65117 124.068 3.58548L121.183 6.35632C120.72 5.87398 120.72 5.12407 121.183 4.64172L124.068 7.41257ZM119.503 12.1657L124.068 7.41257L121.183 4.64172L116.618 9.39484L119.503 12.1657ZM115.604 12.1657C116.671 13.2768 118.436 13.2768 119.503 12.1657L116.618 9.39484C117.125 8.86708 117.982 8.86708 118.488 9.39484L115.604 12.1657ZM115.604 8.33859C114.58 9.40429 114.58 11.1 115.604 12.1657L118.488 9.39484C118.952 9.87719 118.952 10.6271 118.488 11.1094L115.604 8.33859ZM119.662 4.1136L115.604 8.33859L118.488 11.1094L122.546 6.88445L119.662 4.1136ZM115.604 2.65945L119.662 6.88445L122.546 4.1136L118.488 -0.111395L115.604 2.65945ZM115.604 -1.16764C114.58 -0.101951 114.58 1.59376 115.604 2.65945L118.488 -0.111395C118.952 0.370948 118.952 1.12086 118.488 1.6032L115.604 -1.16764ZM119.503 -1.16764C118.436 -2.27875 116.671 -2.27875 115.604 -1.16764L118.488 1.6032C117.982 2.13096 117.125 2.13096 116.618 1.6032L119.503 -1.16764Z"
                    fill="#F40146" mask="url(#path-2-inside-1_729_10178)"/>
            </svg>
            <div data-scroll
                 data-scroll-speed="-2" class="images-inner">
                <div class="background"></div>
                <picture v-for="(item,index) in slides">
                    <source
                        media="(max-width:767px)"
                        :srcset="item.mobile"
                    />
                    <source
                        media="(max-width:1023px)"
                        :srcset="item.tablet"
                    />
                    <img class="slide-image"
                         :class="{active:index===activeSlideIndex}"
                         :src="item.desktop"
                    />
                </picture>
            </div>
            <div class="swiper-inner">
                <div class="swiper-container history-swiper">
                    <div class="swiper-wrapper ">
                        <div v-for="(item,index) in slides" :key="index" class="swiper-slide">
                            <span class="title luxury">{{ item.title }}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>


<script setup>
import {computed, onMounted, ref} from "vue";
import Swiper from "swiper";

const props = defineProps({
    contentData: {
        type: Object,
    },
});
let activeSlideIndex = ref(0);
let slides = computed(() => props.contentData.data?.list?.map(item => ({
    ...item,
    mobile: item?.images?.[0]?.devices?.mobile,
    desktop: item?.images?.[0]?.devices?.desktop,
    tablet: item?.images?.[0]?.devices?.tablet,
})))
let swiper = ref(null)
const changeMobileHeight = () => {
    if (window.innerWidth >= 1024) return
    let item = document.querySelector('#leftBox')
    let itemContentHeight = document.querySelector('#leftBox .item-content.active')?.getBoundingClientRect()?.height

    item.style.height = itemContentHeight + 200 + 'px'
}
const setSwiper = () => {
    swiper = new Swiper('.history-swiper', {
        spaceBetween: 0,
        speed: 100,
        slidesPerView: 1,
        centeredSlides: true,
        freeMode: false,
        slideToClickedSlide: true,
        watchSlidesVisibility: true,
        watchSlidesProgress: true,
        allowTouchMove: false,
        loop: true,
        breakpoints: {
            767: {
                spaceBetween: 35,
                speed: 1200,
                slidesPerView: 4.5,
                loop: false,
            },
            1365: {
                spaceBetween: 55,
                speed: 1200,
                slidesPerView: 5,
                loop: false,
            },
            1650: {
                spaceBetween: 55,
                speed: 1200,
                slidesPerView: 4.5,
                loop: false,
            },
        },
    });

    swiper.on('slideChangeTransitionStart', (e) => {
        let index = e.activeIndex
        activeSlideIndex.value = index >= slides.value?.length ? slides.value?.length - 1 : index
        if (window.innerWidth < 1024) {
            activeSlideIndex.value = index >= slides.value?.length ? index % 2 : index
        }
    });
    swiper.on('slideChangeTransitionEnd', (e) => {
        changeMobileHeight()
    });
}

const nextSwipe = () => {
    swiper.slideNext()
}
const prevSwipe = () => {
    swiper.slidePrev()
}
onMounted(() => {
    setSwiper()
    changeMobileHeight()
})
</script>


<style lang="scss" scoped>
$ease: cubic-bezier(.11, 0, .21, .98);
.history-slider {
    width: 100%;
    position: relative;
    background: $primaryNavy;
    color: white;
    height: 377px;
    display: flex;
    align-items: center;
    margin-top: 89px;
    @media (max-width: 767px) {
        flex-direction: column-reverse;
        height: fit-content;
    }

    .box {
        width: 50%;
        height: 100%;
        position: relative;
        overflow: hidden;
        @media (max-width: 767px) {
            width: 100%;
            height: unset;
        }
    }

    .icon {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 20px;
        z-index: 10;
        cursor: pointer;
        @media only screen and (max-width: 1650px) and (min-width: 1024px) {
            width: 80px;
            left: 10px;
        }
        @media (max-width: 767px) {
            display: none;
        }
    }

    .left {
        transition: all .45s ease-out;
        @media (max-width: 767px) {
            height: 300px;
        }

        .mobile-arrows {
            display: none;
            position: absolute;
            left: 50%;
            top: 35px;
            transform: translate(-50%, 0);
            z-index: 12;
            @media (max-width: 767px) {
                display: flex;
                align-items: center;
            }

            .item {
                padding: 5px 0;

                &:nth-child(2) {
                    svg {
                        transform: rotate(180deg);
                        margin-left: 10px;
                        margin-top: 0;
                    }
                }
            }

            svg {
                margin-top: -1px;

            }
        }

        .item-content {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            position: absolute;
            left: 20%;
            top: 50%;
            transform: translateY(-50%);
            @media only screen and (max-width: 1440px) {
                left: 50px;
            }
            @media (max-width: 767px) {
                position: absolute;
                width: 254px;
                left: 50%;
                top: 100px;
                transform: translateX(-50%);
                align-items: center;
            }

            &.active {
                .header {
                    transform: translateY(0);
                    opacity: 1;
                    transition: all .45s .4s $ease;
                }

                .title {
                    opacity: 1;
                    transform: translateY(0);
                    transition: all .45s .5s $ease;
                }

                .description {
                    opacity: 1;
                    transform: translateY(0);
                    transition: all .45s .7s $ease;
                }
            }

            .header {
                color: $textRed;
                font-weight: 500;
                font-size: 21px;
                line-height: 25px;
                opacity: 0;
                transform: translateY(20px);
                transition: all .45s .15s $ease;
            }

            .title {
                margin-top: 28px;
                font-weight: 400;
                font-size: 89px;
                text-transform: uppercase;
                opacity: 0;
                transform: translateY(40px);
                transition: all .45s .1s $ease;
                @media (max-width: 767px) {
                    font-size: 55px;
                    margin-top: 13px;
                }
            }

            .description {
                display: block;
                font-style: normal;
                margin-top: 18px;
                font-weight: 500;
                font-size: 21px;
                opacity: 0;
                transform: translateY(40px);
                transition: all .45s $ease;
                @media (max-width: 767px) {
                    font-size: 16px;
                    text-align: center;
                    margin-top: 10px;
                }
            }
        }

        .icon {
            transform: rotate(180deg) translateY(50%);
            right: 20px;
            left: unset;
            cursor: pointer;
            @media only screen and (max-width: 1650px) and (min-width: 1024px) {
                right: 10px;
            }
        }

    }

    .right {
        @media (max-width: 767px) {
            height: 260px;
        }

        .images-inner {
            pointer-events: none;
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 120%;

            .background {
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
                background: linear-gradient(0deg, rgba(37, 34, 65, 0.5), rgba(37, 34, 65, 0.5));
                z-index: 1;
                @media (max-width: 767px) {
                    display: none;
                }

            }

            .slide-image {
                position: absolute;
                width: 100%;
                height: 100%;
                object-fit: cover;
                left: 0;
                top: 0;
                opacity: 0;
                transform: scale(.95);
                transition: all .45s $ease;

                &.active {
                    opacity: 1;
                    transform: scale(1);
                }
            }

        }

        .swiper-inner {
            position: absolute;
            top: 50%;
            transform: translateY(-50%) translateX(-150px);
            width: 100%;
            z-index: 2;
            @media only screen and (max-width: 1650px) and (min-width: 1024px) {
                transform: translateY(-50%) translateX(-100px);
            }
            @media (max-width: 767px) {
                opacity: 0;
            }

            .swiper-container {
                .swiper-slide-next + .swiper-slide + .swiper-slide {
                    .title {
                        opacity: .1 !important;
                    }
                }

                .swiper-slide {
                    transition: all .45s .2s $ease;
                    opacity: 0;
                    cursor: pointer;
                    user-select: none;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    pointer-events: none;


                    &:last-child {
                        opacity: 1;
                    }

                    &.swiper-slide-active ~ .swiper-slide {
                        opacity: 1 !important;
                        pointer-events: auto;
                    }

                    .title {
                        font-weight: 400;
                        font-size: 89px;
                        text-transform: uppercase;
                        opacity: 0.3;
                        transition: all .45s $ease;
                        @media only screen and (max-width: 1650px) and (min-width: 1024px) {
                            font-size: 55px;
                        }
                    }
                }
            }
        }

    }
}
</style>
